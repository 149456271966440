import Vue from 'vue'
import router from './router'
import http from './events/Http'
import store from './events/Store'
import config from './events/Config'

import App from './App.vue'

import VueTippy, { TippyComponent } from 'vue-tippy'
import { VuejsDatatableFactory } from 'vuejs-datatable'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueSimpleAlert from 'vue-simple-alert'
import Moment from 'moment-timezone'
import 'moment/locale/ru'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import VModal from 'vue-js-modal'

import VueECharts from 'vue-echarts'
import '@vue/composition-api'
import 'echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/polar'


Vue.config.productionTip = false

Vue.prototype.$config = config
Vue.prototype.$axios = http

Moment.tz.setDefault(config.Timezone)
Moment.locale('ru')
Vue.prototype.$moment = Moment

Vue.use(VueTippy)
Vue.use(VueToast, {
    position: 'top-right',
    duration: 3000
})
Vue.use(VueReCaptcha, {
  siteKey: config.RecaptchaV3.token
})
Vue.use(VuejsDatatableFactory)
Vue.use(VueSimpleAlert)
Vue.use(VModal)

Vue.component('tippy', TippyComponent)
Vue.component('vue-select', vSelect)
Vue.component('date-range-picker', DateRangePicker)
Vue.component('v-chart', VueECharts)


Vue.mixin({
  methods: {
    setTheme (theme) {
        store.commit('setTheme', theme)
    },
    setLoaded (data) {
        store.commit('setLoaded', data)
    },
    setLocalization (lang) {
        store.commit('setLocalization', lang)
    },
    setUser (data) {
        store.commit('setUser', data)
    },
    setNotificationCampaigns (count) {
        store.commit('setNotificationCampaigns', count)
    },
    setNotificationWithdrawal (count) {
        store.commit('setNotificationWithdrawal', count)
    },
    async recaptchav3() {
      await this.$recaptchaLoaded()
      return await this.$recaptcha(config.RecaptchaV3.action)
    }
  }
})

new Vue({
    el: '#app',
    router,
    store,
    computed: {
      auth () {
        return store.state.user.auth
      }
    },
    watch: {
      auth (auth, old) {
        var route = this.$router.currentRoute

        if (!auth && route.meta.auth == 'auth') {
          this.$axios.defaults.headers.common.Authorization = null
          router.push({name: 'Login'})
        } else if (auth && route.meta.auth == 'guest') {
          router.push({name: 'CampaignsItems'})
        }
      }
    },
    render: (h) => h(App)
})
